import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'next/link'

import {HELP_SECTION_URL} from 'utils/const'

import styles from './menu.module.scss'

const Menu = ({isOpen, onItemClick}) => {
  return (
    <div
      className={classNames('navbar-menu has-text-centered', styles.menu, {
        'is-active': isOpen,
      })}>
      <div className="navbar-end">
        <Link href="/chats/create">
          <a
            className={classNames(
              'is-size-7 is-uppercase has-text-green navbar-item',
              styles.menuItem
            )}
            onClick={onItemClick}>
            Create
          </a>
        </Link>
        <Link href={HELP_SECTION_URL}>
          <a
            className={classNames(
              'is-size-7 is-uppercase has-text-green navbar-item',
              styles.menuItem
            )}
            onClick={onItemClick}>
            Help
          </a>
        </Link>
      </div>
    </div>
  )
}

Menu.defaultProps = {
  isOpen: false,
}

Menu.propTypes = {
  isOpen: PropTypes.bool,
  onItemClick: PropTypes.func,
}

export default Menu
