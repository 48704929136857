import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Footer = ({className}) => (
  <section className={classNames('footer has-background-green', className)}>
    <div className="content has-text-centered has-text-white">
      <p className="paragraph is-size-7">
        © 2020{' '}
        <a className="has-text-white" href="https://reden.me">
          reden.me
        </a>
      </p>
      <p className="is-size-7">Built with &#x2764; in Berlin</p>
    </div>
  </section>
)

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
