import {useRef} from 'react'
import useOnClickOutside from 'use-onclickoutside'
import useToggle from './useToggle'

export default function useToggleWithClickOutside(defaultIsOpen) {
  const ref = useRef(null)
  const {isOpen, open, close, toggle} = useToggle(defaultIsOpen)

  useOnClickOutside(ref, close)

  return {ref, isOpen, open, close, toggle}
}
