import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'next/link'

const NavBrand = ({isOpen, onClickHamburgerIcon}) => (
  <div className="navbar-brand">
    <Link href="/">
      <a className="navbar-item">
        <h3 className="logo has-text-green is-size-4 is-family-calistoga">
          reden.me
        </h3>
      </a>
    </Link>
    <span
      role="button"
      className={classNames('navbar-burger', {'is-active': isOpen})}
      aria-label="menu"
      aria-expanded="false"
      onClick={onClickHamburgerIcon}>
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </span>
  </div>
)

NavBrand.defaultProps = {
  isOpen: false,
}

NavBrand.propTypes = {
  isOpen: PropTypes.bool,
  onClickHamburgerIcon: PropTypes.func,
}

export default NavBrand
