import {useState, useCallback} from 'react'

export default function useToggle(defaultIsOpen) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const toggle = useCallback(() => setIsOpen(active => !active), [])
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  return {isOpen, toggle, open, close}
}
