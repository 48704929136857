import PropTypes from 'prop-types'
import classNames from 'classnames'

import NavigationBar from 'modules/common/components/NavigationBar'
import Footer from 'modules/common/components/Footer'

const DefaultLayout = ({
  containerClassName,
  hero,
  heroContainerClassName,
  heroBodyClassName,
  children,
  hideNavigation,
  footerClassName,
}) => {
  return (
    <div className={containerClassName}>
      <section
        className={classNames(
          'hero is-warning is-bold',
          heroContainerClassName
        )}>
        <div className={classNames('hero-head', 'is-container')}>
          {!hideNavigation && <NavigationBar />}
        </div>
        {hero && (
          <div
            className={classNames(
              'hero-body',
              'is-container',
              heroBodyClassName
            )}>
            <div className={classNames('container')}>{hero}</div>
          </div>
        )}
      </section>
      {children}
      <Footer className={footerClassName} />
    </div>
  )
}

DefaultLayout.defaultProps = {
  hero: null,
  hideNavigation: false,
  children: null,
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  hero: PropTypes.node,
  heroBodyClassName: PropTypes.string,
  heroContainerClassName: PropTypes.string,
  hideNavigation: PropTypes.bool,
}

export default DefaultLayout
