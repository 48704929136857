import React, {memo} from 'react'
import classNames from 'classnames'

import useToggleWithClickOutside from 'hooks/useToggleWithClickOutside'
import NavBrand from './NavBrand'
import Menu from './Menu'

import styles from './index.module.scss'

const NavigationBar = memo(() => {
  const {ref, isOpen, toggle, close} = useToggleWithClickOutside(false)

  return (
    <nav className={classNames('navbar', styles.nav)}>
      <div className="container" ref={ref}>
        <NavBrand isOpen={isOpen} onClickHamburgerIcon={toggle} />
        <Menu isOpen={isOpen} onItemClick={close} />
      </div>
    </nav>
  )
})

export default NavigationBar
